import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import Hero from "../components/templates/common/Hero"
import BoldTitle from "../components/templates/common/BoldTitle"
import IconLinks from "../components/templates/common/IconLinks"
import BlueBlockTitle from "../components/templates/treatments/BlueBlockTitle"
import CommonBites from "../components/templates/treatments/CommonBites"
import CleanContent from "../components/templates/common/CleanContent"
import ReadyHero from "../components/templates/common/ReadyHero"

const Treatments = props => {
  const {
    hero,
    boldTitle,
    cleanContent,
    iconLinks,
    blueBlockTitle,
    commonBites,
    readyHero,
    seoInfo,
  } = props.data

  const heroData = hero.template.templatePageTreatments
  const boldTitleData = boldTitle.template.templatePageTreatments
  const cleanContentData = cleanContent.template.templatePageTreatments
  const readyHeroData = readyHero.template.templatePageTreatments
  const iconLinksData = iconLinks.template.templatePageTreatments
  const blueBlockTitleData = blueBlockTitle.template.templatePageTreatments
  const commonBitesData = commonBites.template.templatePageTreatments

  return (
    <Layout>
      <Seo
        title={seoInfo.seoFields.swbThemeMetaTitle}
        description={seoInfo.seoFields.swbThemeDescription}
        //metaImg={seoInfo.seoFields.swbThemeImage.localFile.relativePath}
        location={props.location.pathname}
      />
      <Hero data={heroData} />
      <BoldTitle data={boldTitleData} />
      <IconLinks data={iconLinksData} />
      <BlueBlockTitle data={blueBlockTitleData} />
      <CommonBites data={commonBitesData} />
      <CleanContent data={cleanContentData} />
      <ReadyHero data={readyHeroData} />
    </Layout>
  )
}

export const treatmentsTempQuery = graphql`
  query treatmentsTempPage($id: String!) {
    seoInfo: wpPage(id: { eq: $id }) {
      seoFields {
        swbThemeDescription
        swbThemeMetaTitle
        swbThemeImage {
          localFile {
            relativePath
          }
        }
      }
    }

    hero: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_Treatments {
          templatePageTreatments {
            heroImage {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }

            heroLogo {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }
        }
      }
    }

    boldTitle: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_Treatments {
          templatePageTreatments {
            boldTitle
            boldTitleContent
          }
        }
      }
    }

    cleanContent: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_Treatments {
          templatePageTreatments {
            cleanContentTitle
            cleanContentParagraphs
          }
        }
      }
    }

    iconLinks: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_Treatments {
          templatePageTreatments {
            iconLinks {
              title
              slug
              image {
                altText
                sourceUrl
                localFile {
                  url
                  childImageSharp {
                    gatsbyImageData(width: 1500)
                  }
                }
              }
            }
          }
        }
      }
    }

    blueBlockTitle: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_Treatments {
          templatePageTreatments {
            blueBlockTitle
            blueBlockTitleContent
          }
        }
      }
    }

    commonBites: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_Treatments {
          templatePageTreatments {
            bites {
              image {
                altText
                sourceUrl
                localFile {
                  url
                  childImageSharp {
                    gatsbyImageData(width: 1000)
                  }
                }
              }
            }
          }
        }
      }
    }

    readyHero: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_Treatments {
          templatePageTreatments {
            readyHeroTitle
            readyHeroContent
            readyHeroButtonText
            readyHeroButtonSlug
            readyHeroImage {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Treatments
