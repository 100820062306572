import React from "react"
import styled from "styled-components"
import { B1White, colors, H1White, medWrapper } from "../../../styles/helpers"

import BackgroundOne from "../../Icons/BackgroundOne"

const BlueBlockTitle = ({ data }) => {
  return (
    <SectionStyled>
      <div className="wrapper">
        <div className="title">
          <h2>{data.blueBlockTitle}</h2>
        </div>
        <div className="content">
          <p>{data.blueBlockTitleContent}</p>
        </div>
      </div>
      <div className="bg-one">
        <BackgroundOne />
      </div>
    </SectionStyled>
  )
}

const SectionStyled = styled.section`
  position: relative;
  background-color: ${colors.colorPrimary};

  .wrapper {
    ${medWrapper};
  }

  .title {
    width: 100%;
    max-width: 90rem;
    margin: 0 auto;
    text-align: center;

    h2 {
      ${H1White};
    }
  }

  .content {
    width: 100%;
    max-width: 90rem;
    margin: 0 auto;
    text-align: center;

    p {
      ${B1White};
    }
  }

  .bg-one {
    position: absolute;
    bottom: -25rem;
    left: -30rem;
    max-width: 40rem;
    margin: auto;
    z-index: 5;

    @media (min-width: 768px) {
      bottom: -25rem;
      left: -30rem;
    }

    @media (min-width: 1025px) {
      bottom: -12.5rem;
      left: -10rem;
    }
  }
`

export default BlueBlockTitle
